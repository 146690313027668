@font-face {
  font-family: "Microsoft YaHei";
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Microsoft YaHei.eot');
  src: local("Microsoft YaHei"),
    url('./assets/fonts/Microsoft YaHei.eot') format("embedded-opentype"),
    url('./assets/fonts/Microsoft YaHei.woff') format("woff2"),
    url('./assets/fonts/Microsoft YaHei.woff') format("woff"),
    url('./assets/fonts/Microsoft YaHei.ttf') format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "SimSun";
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/SimSun.eot');
  src: local("SimSun"),
    url('./assets/fonts/SimSun.eot') format("embedded-opentype"),
    url('./assets/fonts/SimSun.woff') format("woff2"),
    url('./assets/fonts/SimSun.woff') format("woff"),
    url('./assets/fonts/SimSun.ttf') format("truetype");
  font-display: swap;
}

* {
  box-sizing: border-box;
  font-family: "Microsoft YaHei",Arial,Helvetica,sans-serif !important;
}

body {
  font-family: "Microsoft YaHei",Arial,Helvetica,sans-serif !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f2f4fa;
}

.topBlue{
border-top-style: solid;
    border-top-color: #3086d0;
    border-top-width: 2px;
}

.main-page-content {
  margin-left: 180px;
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 20px 20px 40px;
  /* background-color: #f2f4fa; */
  background-color: rgba(211,211,211,0.2);
}

.main-page-content.small-left-right-padding {
  padding-left: 8px;
  padding-right: 8px;
}

.MuiButton-root {
  font-family: "Microsoft YaHei",Arial,Helvetica,sans-serif;
}

.MuiButton-label {
  height: 100%;
}

.MuiInputBase-input {
  font-family: "Microsoft YaHei",Arial,Helvetica,sans-serif !important;
}

.MuiButtonBase-root .MuiButton-label {
  text-transform: none;
}

.darin-panel-one {
  position: relative;
  border: 1px solid lightgrey;
  padding: 20px 30px;
  background-color: white;
  margin-bottom: 70px !important;  
}

.darin-panel-one.has-header {
  padding-top: 60px;
}

.darin-panel-one.small-margin-bottom {
  margin-bottom: 50px !important;
}

.darin-panel-one.has-header .darin-panel-one-header {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: lightgray;
  padding-left: 34px;
  padding-right: 34px;
  font-weight: 600;
  font-size: 1.1rem;
}

.darin-form-group {
  display: flex;
  margin-bottom: 1rem;
}

.darin-form-group .form-label {
  line-height: 40px;
  text-align: left;
}

.darin-panel-one .MuiOutlinedInput-root {
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.darin-table-panel {  
  border: lightgray;
  position: relative;
  overflow-y: visible !important;
}

.darin-table-panel .table-action-wrapper {  
  display: flex;
  align-Items: center;
}

.darin-table-panel .darin-table-container {
  overflow-x: auto;
  background-color: white;
  padding: 16px 20px;  
}

.darin-table-panel .darin-table-container .MuiTableCell-body{
  word-wrap: break-word;
}

.darin-table-panel .darin-table-container-wrapper  {
  overflow: hidden;
  background-color: white;
  padding: 16px 20px;  
}

.darin-table-panel .darin-table-container-wrapper .darin-table-container {
  padding: 0
}

.darin-table-panel .table-action-wrapper .table-pagination-wrapper {
  margin-left: auto;
  border-bottom: 0;
}

.darin-table-container .MuiTableCell-head {
  border: none;
  font-size: 0.9rem;
  font-weight: 600;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: center;
  text-transform: capitalize;
}

.darin-table-container .MuiTableBody-root {
  z-index: 0;
}

.darin-table-container .MuiCheckbox-root {
  z-index: 0;
}

.darin-table-container .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
  padding-top: 4px;
  padding-bottom: 4px;
  height: 52px;
  text-align: center;
}

.darin-table-container .MuiTableRow-root .MuiTableCell-root:first-child {
  padding-left: 20px;
}

.darin-table-container .MuiTableRow-root .MuiTableCell-root:last-child {
  padding-right: 20px;
}

.darin-table-container .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-body {
  border: none;
  padding-bottom: 0;
}

.darin-table-container .btn-table-action { 
    padding: 0;
    margin-right: 16px;
}

.darin-table-container .btn-table-action:last-child {
  margin-right: 0;
}

.darin-table-container .table-checkbox {
  padding: 0;
}

.darin-table-container .MuiSelect-root  {
  padding-top: 8px;
  padding-bottom: 8px;
}

.page-title-wrapper {
  display: flex;
  align-items: center;
  margin: 0 0 16px 0;  
  height: 48px;
}

.page-title {
  color: #1477d6;
  text-align: left;  
}

.chat-btn-primary.MuiButton-root {
  color: #1477d6;
  background-color: transparent;  
  font-weight: 600;
  box-shadow: -1px 1px 5px 0px rgba(0,0,0,0.2);
  -webkit-box-shadow: -1px 1px 5px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: -1px 1px 5px 0px rgba(0,0,0,0.2);
  border: none !important;
}

.swal2-styled.swal2-confirm {
  box-shadow: -1px 1px 5px 0px rgba(0,0,0,0.2) !important;
  -webkit-box-shadow: -1px 1px 5px 0px rgba(0,0,0,0.2) !important;
  -moz-box-shadow: -1px 1px 5px 0px rgba(0,0,0,0.2) !important;
  color: #1477d6 !important;
  background-color: transparent !important;  
  font-weight: 600 !important;
  outline: none !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  border: none !important;
}

.darin-breadcrumbs {
  display: flex;
  font-family: "Microsoft YaHei",Arial,Helvetica,sans-serif !important;
  margin-right: auto !important;
}

.darin-breadcrumbs .MuiBreadcrumbs-li .MuiLink-root {
  cursor: pointer;
}

.drag-handler{
  top: 50px;
  left: 170px;
  width: 10px; 
  color: white;
  font-size: 9px;
  height: calc(100% - 50px);
  z-index: 1234567;
  overflow: visible;
  position: fixed;
  transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  padding-top: 0;
  background-image: linear-gradient(to bottom, #3b75a9 , #7db0de);
  display: none; 
  /*align-items: center;垂直居中*/
  /* background-color: #f2f4fa; */
  /* background-color: rgba(231, 20, 20, 0.904); */
}

nav {
  display: 'block';
} 
@media (max-width: 768px) {
  .main-page-content {
    margin-left: 0;
  }
  .drag-handler {
    left: 0px;
    display: flex;
  }
  nav {
    display: none;
  }
  .makeStyles-date-25{
    display:'none' !important;
    font-size:0px !important;
  }
}